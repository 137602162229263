import { Injectable } from '@angular/core'
import * as _ from 'lodash'

import { PageDto } from '@mms/dto/page.dto'

import { HttpService } from './http.service'

@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
    
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async findOne(slug: string) {
    return await this.httpService.get<PageDto>(`/page/slug/${slug}`)
  }
}
