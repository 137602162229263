import { BaseDto } from './base.dto'

const NO_IMAGE_URL = './assets/images/no-image.png'

export class FileDto extends BaseDto {
  public url: string = NO_IMAGE_URL
  public name: string
  public path: string
  public type: string
  public hasChildren: boolean
  public isDirectory: boolean

  public get directory() {
    return this.path + '/' + this.name
  }

  public static createFolder(name: string, path: string) {
    const folder = new FileDto()

    folder.name = name
    folder.path = path
    folder.isDirectory = true

    return folder
  }

  public get thumbnailUrl() {
    const type = this.isDirectory ? 'folder' : this.type ? this.type.split('/')[0] : 'file'

    return type == 'image' ? this.url : NO_IMAGE_URL
  }
}
