import { Injectable } from '@angular/core'
import * as _ from 'lodash'

import { SettingDto } from '../dto/setting.dto'
import { HttpService } from './http.service'


@Injectable()
export class SettingService {
  constructor(
    private httpService: HttpService) {
    
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async map() {
    const settings = await this.httpService.get<SettingDto[]>('/settings')

    return _.keyBy(settings, 'key')
  }
}
