import { Injectable } from '@angular/core'

import { plainToClass } from 'class-transformer'

import { FeatureDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class FeatureService {
  constructor(
    private httpService: HttpService) {
  }

  async create(projectId: string, groupId: string, layerId: string, feature: FeatureDto) {
    const result = await this.httpService.post('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId + '/features', feature)

    return plainToClass(FeatureDto, result)
  }

  async bulkCreate(projectId: string, groupId: string, layerId: string, features: FeatureDto[]) {
    const result = await this.httpService.post<any[]>('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId + '/features/bulk', {features})

    return plainToClass(FeatureDto, result)
  }

  async update(featureId: string, projectId: string, groupId: string, layerId: string, feature: FeatureDto) {
    return await this.httpService.put<boolean>('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId + '/features/' + featureId, feature)
  }

  async find(projectId: string, groupId: string, layerId: string) {
    const result = await this.httpService.get<any[]>('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId)
    
    return plainToClass(FeatureDto, result)
  }

  async findOne(featureId: string, projectId: string, groupId: string, layerId: string, ) {
    const result = await this.httpService.get<any>('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId + '/features/' + featureId)
    
    return plainToClass(FeatureDto, result)
  }
}
