import { Type } from 'class-transformer'

import { BaseDto } from './base.dto'
import { FileDto } from './file.dto'

export class ReportDto extends BaseDto {
  public name: string
  public descriptions: string
  public categoryId: string
  public parentId: string

  @Type(() => Number)
  public latitude: number

  @Type(() => Number)
  public longitude: number

  @Type(() => FileDto)
  public attachments: FileDto[]

  @Type(() => FileDto)
  public images: FileDto[]

  @Type(() => ReportDto)
  public children: ReportDto[]

  public static create() {
    const report = new ReportDto()

    report.name = ''
    report.descriptions = ''
    report.attachments = []

    return report
  }
}
