import { BaseDto } from './base.dto'

export class FeatureDto extends BaseDto {
  public type: string  
  public properties: any
  public geometry: any

  public static create({type, properties, geometry}: any) {
    const feature = new FeatureDto()

    feature.type = type || 'Feature'
    feature.properties = properties
    feature.geometry = geometry

    return feature
  }
}