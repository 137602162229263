import { createAction, createReducer, on, props } from '@ngrx/store'

import * as _ from 'lodash'

import { UserDto } from '@mms/dto'

export const initialState = { authUser: null }
export const setAuthUser = createAction('[Auth] setAuthUser', props<{authUser: UserDto}>())
export const authReducer = createReducer(initialState,
  on(setAuthUser, (state, {authUser}) => {
    const newState = _.clone(state)

    newState.authUser = authUser

    return newState
  })
)