import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { Store } from '@ngrx/store'
import { plainToClass } from 'class-transformer'
import { Router } from '@angular/router'

import { setAuthUser } from '@mms/store/auth'
import { UserDto, GrantsDto } from '@mms/dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'


@Injectable()
export class AuthService {
  constructor(
    private httpService: HttpService,
    private fileService: FileService,
    private router: Router,
    private store: Store<any>) {
  }

  async current() {
    const sessionToken = await this.httpService.getSessionToken()
    let user

    console.log('sessionToken: ' + sessionToken)

    if (sessionToken) {
      try {
        const result = await this.httpService.get<any>('/auth/user')
        
        user = plainToClass(UserDto, result)
        
        const authUser = _.clone(user)
  
        this.store.dispatch(setAuthUser({authUser}))
      } catch (error) {
        await this.httpService.setSessionToken(undefined, true)
  
        window.location.reload()
      }
    }

    return user
  }

  async update(user: UserDto) {
    await this.httpService.put<boolean>('/auth/user', user)
  }

  async logIn(username: string, password: string, shouldRemember: boolean) {
    const { sessionToken, user } = await this.httpService.get('/auth/login', {username, password})

    await this.httpService.setSessionToken(sessionToken, shouldRemember)

    return user
  }

  async logOut() {
    await this.httpService.post('/auth/logout')

    return await this.httpService.removeSessionToken()
  }

  async getGrants() {
    return await this.httpService.get<GrantsDto>('/auth/grants')
  }

  async getPermissions() {
    return await this.httpService.get<string[]>('/auth/permissions')
  }

  async updatePassword(password: string) {
    return await this.httpService.patch<boolean>('/auth/password', {password})
  }

  async uploadPhoto(photo: File) {
    return this.fileService.uploadPath('/auth/photo', photo, false)
  }
}
