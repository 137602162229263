import { Injectable } from '@angular/core'

import { plainToClass } from 'class-transformer'

import { LayerGroupDto, SetPositionsDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class LayerGroupService {
  constructor(
    private httpService: HttpService) {
  }

  async create(projectId: string, group: LayerGroupDto) {
    const result = await this.httpService.post('/projects/' + projectId + '/groups', group)

    return plainToClass(LayerGroupDto, result)
  }

  async update(groupId: string, projectId: string, group: LayerGroupDto) {
    return await this.httpService.put<boolean>('/projects/' + projectId + '/groups/' + groupId, group)
  }

  async setPositions(projectId: string, setPositions: SetPositionsDto) {
    return await this.httpService.put<boolean>('/projects/' + projectId + '/groups/positions', setPositions)
  }

  async deleteById(groupId: string, projectId: string) {
    return await this.httpService.delete('/projects/' + projectId + '/groups/' + groupId)
  }

  async find(projectId: string) {
    const result = await this.httpService.get<any[]>('/projects/' + projectId + '/groups',)
    
    return plainToClass(LayerGroupDto, result)
  }
}
