import { Injectable } from '@angular/core'

import { RoleDto } from '../dto'
import { HttpService } from './http.service'
import { plainToClass } from 'class-transformer'

@Injectable()
export class RoleService {
  constructor(
    private httpService: HttpService) {
    
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async find() {
    const roles = await this.httpService.get<any[]>('/roles')

    return plainToClass(RoleDto, roles)
  }

  async findOne(id: string) {
    const role = await this.httpService.get<any>('/roles/' + id)
    
    return plainToClass(RoleDto, role)
  }

  async setPermission(roleId: string, permissionIds: string[]) {
    return await this.httpService.post<boolean>('/roles/' + roleId + '/permissions', {permissionIds})
  }

  async destroy(id: string) {
    return await this.httpService.delete<RoleDto>('/roles/' + id)
  }
}

