import * as _ from 'lodash'

interface INode {
  id?: string
}

interface ILayerGroup {
  id?: string,
  layers: INode[]
}

interface IProject extends INode {
  children?: IProject[]
  attachments?: INode[]
  groups?: ILayerGroup[]
}

interface IReport extends IProject {}

export class GrantsDto {
  public projects: IProject[]
  public reports: IReport[]

  static create() {
    const grants = new GrantsDto()

    grants.projects = []
    grants.reports = []

    return grants
  }
}
