import { Injectable } from '@angular/core'

import { plainToClass } from 'class-transformer'

import { ReportDto, FindReportDto, SetPositionsDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class ReportService {
  constructor(private httpService: HttpService) {
  }

  async create(report: ReportDto) {
    return await this.httpService.post<ReportDto>('/reports', report)
  }

  async update(id: string, report: ReportDto) {
    return await this.httpService.put<ReportDto>('/reports/' + id, report)
  }

  async find(options: FindReportDto) {
    const result = await this.httpService.get<any[]>('/reports', options)
    
    return plainToClass(ReportDto, result)
  }

  async findOne(id: string) {
    const result = await this.httpService.get('/reports/' + id)

    return plainToClass(ReportDto, result)
  }

  async destroy(id: string) {
    return await this.httpService.delete('/reports/' + id)
  }

  async setPositions(setPositions: SetPositionsDto) {
    return await this.httpService.put<boolean>('/reports/positions', setPositions)
  }

  async setThumbnail(id: string, thumbnailId: string) {
    return await this.httpService.put('/reports/' + id + '/thumbnail', {thumbnailId})
  }

  async removeThumbnail(id: string) {
    return await this.httpService.delete('/reports/' + id + '/thumbnail')
  }

  async addAttachment(id: string, attachmentId: string) {
    return await this.httpService.put('/reports/' + id + '/attachments', {attachmentId})
  }

  async removeAttachment(id: string, attachmentId: string) {
    return await this.httpService.delete('/reports/' + id + '/attachments/' + attachmentId)
  }

  async addImage(id: string, imageId: string) {
    return await this.httpService.put('/reports/' + id + '/images', {imageId})
  }

  async removeImage(id: string, imageId: string) {
    return await this.httpService.delete('/reports/' + id + '/images/' + imageId)
  }
}
