import { Injectable } from '@angular/core'

import { Geolocation } from 'ol'
import Projection from 'ol/proj/Projection'

@Injectable()
export class LocationService {
  private currentLocation: number[]

  constructor() {

  }

  async current(projection: Projection) {
    if (!this.currentLocation) {
      this.currentLocation = await new Promise<number[]>((resolve, reject) => {
        const geolocation = new Geolocation({
          trackingOptions: {
            enableHighAccuracy: true
          },
          projection: projection
        })
    
        geolocation.on('error', (error) => {
          reject(error)
        })
    
        geolocation.on('change:position', () => {
          resolve(geolocation.getPosition())
        })
    
        geolocation.setTracking(true)
      })
    }

    return this.currentLocation
  }
}
