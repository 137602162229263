import { Type } from 'class-transformer'

import { BaseDto } from './base.dto'
import { FeatureDto } from './feature.dto'
import { FileDto } from './file.dto'

export enum LayerType {
  Vector = 'VECTOR',
  Raster = 'RASTER',
  Points = 'POINTS',
  Polygon = 'POLYGON',
  DTM = 'DTM',
}

export class LayerDto extends BaseDto {
  public name: string
  public type: LayerType
  public sortOrder: string
  public datasource: string
  public rasterFile: string
  public defaultVisible: boolean
  public opacity: number
  public symbol: number

  @Type(() => FeatureDto)
  public features: FeatureDto[]

  @Type(() => FileDto)
  public file: FileDto

  public static create(type: LayerType) {
    const layer = new LayerDto()

    layer.type = type

    return layer
  }
}