import { Type } from 'class-transformer'

import { BaseDto } from './base.dto'
import { LayerGroupDto } from './layer-group.dto'
import { FileDto } from './file.dto'

export class ProjectDto extends BaseDto {
  public name: string
  public descriptions: string
  public datasource: string
  public rasterFile: string
  public categoryId: string

  @Type(() => LayerGroupDto)
  public layerGroups: LayerGroupDto[]

  @Type(() => FileDto)
  public attachments: FileDto[]

  @Type(() => FileDto)
  public images: FileDto[]

  @Type(() => ProjectDto)
  public children: ProjectDto[]

  @Type(() => FileDto)
  public thumbnail: FileDto

  public static create() {
    const project = new ProjectDto()

    project.name = ''
    project.descriptions = ''
    project.layerGroups = []
    project.attachments = []

    return project
  }
}
