import { Injectable } from '@angular/core'

import { ContactUsDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class ContactUsService {
  constructor(
    private httpService: HttpService) {
  }

  async send(contactUs: ContactUsDto) {
    return this.httpService.post<boolean>('/contact-us', contactUs)
  }
}
