import { Component, ViewEncapsulation } from '@angular/core'
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { CategoryService, SettingService } from '@mms/services';

// import { AppService } from './app.service'
import * as _ from 'lodash'

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private metas = []
  private settings = {}
  
  constructor(
    private metaService: Meta,
    private titleService: Title,
    private categoryService: CategoryService,
    private settingService: SettingService) {
    
  }

  public async ngOnInit() {
    await this.fetchMetas()
  }

  private async fetchMetas() {
    try {
      this.metas = await this.categoryService.findMetas()
      this.settings = await this.settingService.map()

      console.log('this.metas', this.metas)
      const categoriesNames = this.metas.map(o => o.name)
      console.log(categoriesNames)

      const projectNames = _.flatten(this.metas.map(o => {
        return o.projects.map(p => p.name)
      }))
      const groupNames = _.flatten(this.metas.map(o => {
        return o.projects.map(p => {
          return p.groups.map(q => q.name)
        })
      }))
      const layerNames = _.flatten(this.metas.map(o => {
        return o.projects.map(p => {
          return p.groups.map(q => {
            return q.layers.map(r => r.name)
          })
        })
      }))
      this.metaService.addTag({ name: 'description', content: categoriesNames.join(', ')});
      this.metaService.addTag({ name: 'description', content: projectNames.join(', ')});
      this.metaService.addTag({ name: 'description', content: groupNames.join(', ')});
      this.metaService.addTag({ name: 'description', content: layerNames.join(', ')});
      this.metaService.addTag({ name: 'description', content: this.settings['Keywords'].value});
      // this.metaService.updateTag({ property: 'og:title', content: 'Content Title for social media' });
    
      
    } catch (error) {
      console.log(error)
    }
  }
}
