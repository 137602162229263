export class PageInfo {
  public page: number = 1
  public pageSize: number = 10
  public collectionSize: number = 30

  public static create() {
    return new PageInfo()
  }

  public get from() {
    return ((this.page - 1) * this.pageSize) + 1
  }

  public get to() {
    const max = this.page * this.pageSize
    
    return this.collectionSize < max ? this.collectionSize : max
  }

  public get limit() {
    return this.pageSize
  }

  public get offset() {
    return (this.page - 1) * this.pageSize
  }

  public row(row) {
    return (row + 1) + ((this.page - 1) * this.pageSize)
  }
}