import { Injectable } from '@angular/core'

import { plainToClass, plainToClassFromExist } from 'class-transformer'

import { FloodMapDto, FindFloodMapDto, SetPositionsDto, PageResultDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class FloodMapService {
  constructor(
    private httpService: HttpService) {
  }

  async create(floodMap: FloodMapDto) {
    const result = await this.httpService.post<FloodMapDto>('/floodmaps', floodMap)

    return plainToClass(FloodMapDto, result)
  }

  async update(id: string, floodMap: FloodMapDto) {
    return await this.httpService.put<FloodMapDto>('/floodmaps/' + id, floodMap)
  }

  async find(queryParams: FindFloodMapDto) {
    const result = await this.httpService.get('/floodmaps/', queryParams)
    
    return plainToClassFromExist(new PageResultDto<FloodMapDto>(FloodMapDto), result)
  }

  async findOne(id: string) {
    const result = await this.httpService.get('/floodmaps/' + id)

    return plainToClass(FloodMapDto, result)
  }

  async destroy(id: string) {
    return await this.httpService.delete('/floodmaps/' + id)
  }

  async setPositions(setPositions: SetPositionsDto) {
    return await this.httpService.put<boolean>('/floodmaps/positions', setPositions)
  }
}
