import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'

import { plainToClass } from 'class-transformer'

import { FileDto } from '../dto'
import { HttpService } from './http.service'

import { FindFileDto } from '@mms/dto'

@Injectable()
export class FileService {
  constructor(
    private httpService: HttpService) {
    
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async upload(file: File, isTemporary: boolean, path?: string) {
    return await this.uploadPath('/files/upload', file, isTemporary, path)
  }

  async uploadPath(uploadPath: string, file: File, isTemporary: boolean, path?: string) {
    const formData = new FormData()
    const headers = new HttpHeaders()
      
    formData.append('file', file)
    formData.append('isTemporary', isTemporary + '')
    
    if (path) {
      formData.append('path', path)
    }

    return await this.httpService.post<FileDto>(uploadPath, formData, {}, headers)
  }

  async find(findFileDto: FindFileDto) {
    const files = await this.httpService.get<FileDto[]>('/files', findFileDto)

    return plainToClass(FileDto, files)
  }

  async findOne(id: string) {
    const file = await this.httpService.get<any>('/files/' + id)

    return plainToClass(FileDto, file)
  }

  async delete(id: string) {
    return await this.httpService.delete('/files/' + id)
  }

  async create(file: FileDto) {
    return await this.httpService.post('/files', file)
  }

  async update(id: string, file: FileDto) {
    return await this.httpService.put('/files/' + id, file)
  }
}

