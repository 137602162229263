import { Injectable } from '@angular/core'

import { plainToClass } from 'class-transformer'

import { FileDto, DataSourceDto } from './../dto'
import { HttpService } from './http.service'

@Injectable()
export class DataSourceService {
  constructor(
    private httpService: HttpService) {
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async find(path: string) {
    const files = await this.httpService.get<DataSourceDto[]>('/datasources', {path})

    return plainToClass(FileDto, files)
  }
}