import { Type } from 'class-transformer'

import { BaseDto } from './base.dto'
import { FileDto } from './file.dto'

export class FloodMapDto extends BaseDto {
  public name: string
  public descriptions: string

  @Type(() => FileDto)
  public attachment: FileDto

  @Type(() => FileDto)
  public thumbnail: FileDto

  public static create() {
    const report = new FloodMapDto()

    report.name = ''
    report.descriptions = ''

    return report
  }
}
