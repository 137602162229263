export enum FileType {
  ARRAY_BUFFER = 0,
  TEXT = 1
}

export const readFile = (file: File, type: FileType) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }
    
    reader.onerror = reject

    switch (type) {
      case FileType.ARRAY_BUFFER:
        reader.readAsArrayBuffer(file)
        break;
      case FileType.TEXT:
        reader.readAsText(file)
        break;
      default:
        reader.readAsText(file)
        break;
    }
  })
}