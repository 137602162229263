import { Type } from 'class-transformer'

export class BaseDto {
  public id?: string

  @Type(() => Date)
  public createdAt: Date

  @Type(() => Date)
  public updatedAt: Date
}