import { Type } from 'class-transformer'

import * as _ from 'lodash'
import * as abbreviate from 'abbreviate'

import { RoleDto } from './role.dto'
import { FileDto } from './file.dto'

export class UserDto {
  public id?: string
  public firstName: string
  public lastName: string
  public username: string
  public password: string
  public email: string
  public enabled: boolean
  public startAt: Date
  public expiresAt: Date

  @Type(() => RoleDto)
  public roles: RoleDto[]

  @Type(() => FileDto)
  public photo: FileDto

  public static create() {
    const user = new UserDto()

    user.enabled = true

    return user
  }

  public get roleNames() {
    return this.roles.map(o => o.displayedName).join(', ')
  }

  public get isSuper() {
    return this.roles.find(o => o.name == 'SUPER_ADMIN')
  }

  public get isAdminOrAbove() {
    return this.roles.find(o => ['SUPER_ADMIN', 'ADMIN'].includes(o.name))
  }

  public get fullName() {
    return this.firstName + ' ' + this.lastName
  }

  public get abbreviation() {
		return _.toUpper(abbreviate(this.fullName, {length: 2}))
	}
}
