import { Exclude, Type } from 'class-transformer'

export class PageResultDto<T> {
  @Exclude()
  private type: Function

  @Type(options => {
    return (options.newObject as PageResultDto<T>).type;
  })
  rows: T[];
  count: number;
  
  constructor(type: Function) {
    this.type = type
  }
}