import { Type } from 'class-transformer'

import { BaseDto } from './base.dto'
import { LayerDto } from './layer.dto'

export class LayerGroupDto extends BaseDto {
  public name: string
  public primary: boolean

  @Type(() => LayerDto)
  public layers: LayerDto[]

  public static create() {
    const group = new LayerGroupDto()

    group.layers = []

    return group
  }
}