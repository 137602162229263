import { Injectable } from '@angular/core'

import { PermissionDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class PermissionService {
  constructor(
    private httpService: HttpService) {
    
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  async find() {
    return await this.httpService.get<PermissionDto[]>('/permissions')
  }
}

