import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { DragDropModule } from '@angular/cdk/drag-drop'

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { AngularSvgIconModule } from 'angular-svg-icon'
import { TimeagoModule } from 'ngx-timeago'
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading'
import { GalleryModule } from '@ks89/angular-modal-gallery'

import { MMSModule } from '@mms/core'

import { ROUTES } from './app.routes'
import { AppComponent } from './app.component'
//import { AppService } from './app.service'

let origin = window.location.origin;

if (!origin) {
  origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

let isDev = (window.location.hostname === 'localhost');

const localhostServerUrl = 'http://localhost:5000';
// const localhostServerUrl = 'https://cddams.com';
// const localhostServerUrl = 'https://api.mms.dev3.coolbeans.studio';
const baseUrl = isDev ? localhostServerUrl : origin;
const SERVER_URL = baseUrl + '/v1'
const config: SocketIoConfig = { url: baseUrl, options: {}}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AngularSvgIconModule,
    RouterModule.forRoot(ROUTES),
    MMSModule.forRoot({
      serverURL: SERVER_URL
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: 'rgba(255,255,255,0.5)',
      primaryColour: '#00d68f', 
      secondaryColour: '#6dedc3', 
      tertiaryColour: '#ffffff'
    }),
    TimeagoModule.forRoot(),
    GalleryModule.forRoot(),
    SocketIoModule.forRoot(config),
    DragDropModule,
  ],
  providers: [
    //AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
