import { Injectable } from '@angular/core'

import { plainToClassFromExist, plainToClass } from 'class-transformer'

import { ProjectDto, FindProjectDto, PageResultDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class ProjectService {
  constructor(private httpService: HttpService) {
  }

  async create(project: ProjectDto) {
    return await this.httpService.post<ProjectDto>('/projects', project)
  }

  async update(id: string, project: ProjectDto) {
    return await this.httpService.put<ProjectDto>('/projects/' + id, project)
  }

  async find(options: FindProjectDto) {
    return await this.httpService.get<ProjectDto[]>('/projects', options)
  }

  async findOne(id: string) {
    return await this.httpService.get<ProjectDto>('/projects/' + id)
  }

  async destroy(id: string) {
    return await this.httpService.delete('/projects/' + id)
  }

  async setDatasource(id: string, datasource: string) {
    return await this.httpService.put('/projects/' + id + '/datasource', {datasource})
  }

  async addAttachment(id: string, attachmentId: string) {
    return await this.httpService.put('/projects/' + id + '/attachments', {attachmentId})
  }

  async removeAttachment(id: string, attachmentId: string) {
    return await this.httpService.delete('/projects/' + id + '/attachments/' + attachmentId)
  }

  async addImage(id: string, imageId: string) {
    return await this.httpService.put('/projects/' + id + '/images', {imageId})
  }

  async removeImage(id: string, imageId: string) {
    return await this.httpService.delete('/projects/' + id + '/images/' + imageId)
  }
}
