import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@mms/common'
import { StylesFilterPipe } from '@mms/utils'
import { SafeHtmlPipe } from '@mms/common/safe-html.pipe'

import { 
  UserService, 
  HttpService, 
  LocalStorageService, 
  RoleService, 
  PermissionService, 
  FileService, 
  ProjectService, 
  CategoryService, 
  LayerService,
  LayerGroupService,
  FeatureService,
  DataSourceService,
  MapService,
  ReportService,
  FloodMapService,
  AuthService,
  LocationService,
  ContactUsService,
  PageService,
  SettingService
} from '@mms/services'

import { MMSModuleOptions } from './mms.module-options'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe,
    SafeHtmlPipe
  ],
  providers:[
    LocalStorageService,
    RoleService,
    UserService,
    HttpService,
    FileService,
    ProjectService,
    PermissionService,
    CategoryService,
    LayerService,
    LayerGroupService,
    DataSourceService,
    FeatureService,
    ReportService,
    MapService,
    FloodMapService,
    AuthService,
    LocationService,
    ContactUsService,
    PageService,
    SettingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  exports: [
    StylesFilterPipe,
    SafeHtmlPipe
  ]
})
export class MMSModule {
  static forRoot(options: MMSModuleOptions): ModuleWithProviders {
    window['serverURL'] = options.serverURL
    
    return <ModuleWithProviders> {
      ngModule: MMSModule,
      providers: [
        {
          provide: MMSModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
