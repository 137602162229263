import { BaseDto } from './base.dto'

export class ContactUsDto extends BaseDto {
  public name: string
  public email: string
  public phoneNumber: string
  public message: string

  static create() {
    return new ContactUsDto()
  }
}
