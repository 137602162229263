import { BaseDto } from './base.dto'

export class DataSourceDto extends BaseDto {
  public path: string
  public name: string
  public isDirectory:  boolean

  public get directory() {
    return this.path + this.name
  }
}