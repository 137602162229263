import { Injectable } from '@angular/core'

import { plainToClass } from 'class-transformer'

import { CategoryDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class CategoryService {
  constructor(
    private httpService: HttpService) {
  }

  async create(category: CategoryDto) {
    return this.httpService.post<CategoryDto>('/categories', category)
  }

  async update(id: string, category: CategoryDto) {
    return this.httpService.put<boolean>('/categories/' + id, category)
  }

  async destroy(id: string) {
    return this.httpService.delete<CategoryDto>('/categories/' + id)
  }

  async find() {
    const categories = await this.httpService.get<any[]>('/categories')
    
    return plainToClass(CategoryDto, categories)
  }

  async findOne(id: string) {
    const result = await this.httpService.get<any>('/categories/' + id)
    
    return plainToClass(CategoryDto, result)
  }

  async findMetas() {
    return await this.httpService.get<any[]>('/categories/metas')
  }
}
