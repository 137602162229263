import { Injectable } from '@angular/core'

import { plainToClass } from 'class-transformer'

import { LayerDto, SetPositionsDto } from '@mms/dto'

import * as _ from 'lodash'
import * as oboe from 'oboe'

import { HttpService } from './http.service'
import { Observable } from 'rxjs'


@Injectable()
export class LayerService {
  constructor(
    private httpService: HttpService) {
  }

  async create(projectId: string, groupId: string, layer: LayerDto) {
    const result = await this.httpService.post('/projects/' + projectId + '/groups/' + groupId + '/layers', layer)

    return plainToClass(LayerDto, result)
  }

  async update(layerId: string, projectId: string, groupId: string, layer: LayerDto) {
    return await this.httpService.put<boolean>('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId, layer)
  }

  async setPositions(projectId: string, groupId: string, setPositions: SetPositionsDto) {
    return await this.httpService.put<boolean>('/projects/' + projectId + '/groups/' + groupId + '/layers/positions', setPositions)
  }

  async addProperties(layerId: string, projectId: string, groupId: string, properties: any) {
    return await this.httpService.patch<boolean>('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId + '/properties', properties)
  }

  async removeProperties(layerId: string, projectId: string, groupId: string, keys: string[]) {
    return await this.httpService.delete<boolean>('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId + '/properties', {keys})
  }

  async find(projectId: string, groupId: string) {
    const result = await this.httpService.get<any[]>('/projects/' + projectId + '/groups/' + groupId + '/layers')
    
    return plainToClass(LayerDto, result)
  }

  async deleteById(layerId: string, projectId: string, groupId: string) {
    return await this.httpService.delete('/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId)
  }

  async features(layerId: string, projectId: string, groupId: string) {
    const url = this.httpService.serverURL + '/projects/' + projectId + '/groups/' + groupId + '/layers/' + layerId

    return new Observable<any>(subscriber => {
      const features = []

      oboe(url)
        .node('features.*', (feature) => {
          features.push(feature)
          subscriber.next(features)
        })
        .done(() => {
          subscriber.complete()
        })
    })
  }
}
