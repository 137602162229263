import { Injectable } from '@angular/core'

import { MapInfoDto } from '@mms/dto'

import { HttpService } from './http.service'

@Injectable()
export class MapService {
  constructor(
    private httpService: HttpService) {

  }

  async info(datasource: string) {
    return await this.httpService.get<MapInfoDto>('/map/info', {datasource})
  }
}
