import { Pipe, PipeTransform } from '@angular/core'

import * as _ from 'lodash' 

@Pipe({name: 'stylesFilter'})
export class StylesFilterPipe implements PipeTransform {
  public transform(value: any[]) {
    return _.filter(value, o => {
      return !_.includes([
        'fillColor', 
        'strokeColor', 
        'textColor', 
        'cluster', 
        'geometry', 
        'icon', 
        'lineStyle', 
        'thickness',
        'displayProperty',
        'mms_radius',
        'mms_scale',
        'mms_textStyle_displayAttribute',
        'mms_textStyle_maxResolution',
        'mms_textStyle_rotation',
        'mms_textStyle_font',
        'mms_textStyle_fontSize',
        'mms_textStyle_weight',
        'mms_textStyle_align',
        'mms_textStyle_color',
        'mms_textStyle_outlineColor',
        'mms_textStyle_outlineWidth',
        'mms_textStyle_placement',
        'mms_textStyle_overflow',
        'mms_gallery_attributeSuffix',
        'mms_gallery_folder'
      ], o.key)
    })
  }
}