import * as _ from 'lodash'
import { plainToClassFromExist, plainToClass } from 'class-transformer'

import { Injectable } from '@angular/core'

import { FindUserDto, PageResultDto, UserDto, ExportUserDto } from './../dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'

@Injectable()
export class UserService {
  constructor(
    private fileService: FileService,
    private httpService: HttpService) {
    
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================
  
  async deleteById(id: string) {
    return this.httpService.delete('/users/' + id)
  }

  async find(findUserDto: FindUserDto) {
    const result = await this.httpService.get('/users', findUserDto)
    
    return plainToClassFromExist(new PageResultDto<UserDto>(UserDto), result)
  }

  async findById(id: string) {
    const result = await this.httpService.get('/users/' + id)
    
    return plainToClass(UserDto, result)
  }

  async create(user: UserDto) {
    return this.httpService.post<UserDto>('/users', user)
  }

  async update(id: string, user: UserDto) {
    return this.httpService.put<boolean>('/users/' + id, user)
  }

  async setRoles(id: string, roleIds: string[]) {
    return this.httpService.post<UserDto>('/users/' + id + '/roles', {roleIds})
  }

  async uploadPhoto(id: string, photo: File) {
    return this.fileService.uploadPath('/users/' + id + '/photo', photo, false)
  }

  async export(exportUserDto: ExportUserDto) {
    return this.httpService.get('/users/export', exportUserDto)
  }
}
