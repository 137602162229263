export class MapInfoDto {
  public basename: string
  public bounds: number[]
  public center: number[]
  public description: string
  public filesize: number
  public format: string
  public id: string
  public maxZoom: string
  public maxzoom: number
  public minZoom: string
  public minzoom: number
  public name: string
  public scheme: string
  public type: string
  public version: string
}

