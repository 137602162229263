export class SetPositionsDto {
  public rows: Array<{id, sortOrder}>

  public static create(rows: Array<{id, sortOrder}>) {
    const setPositions = new SetPositionsDto()

    setPositions.rows = rows

    return setPositions
  }
}